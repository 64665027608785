/**
 * Created by sanchez 
 */
'use strict';

//check the environment
// if (process.env.NODE_ENV !== 'production') {
//     console.log('Looks like we are in development mode!');
// }

// import CSS
// import animate_css from 'animate.css/animate.min.css';
// import css from '../css/css.css';
import scss from '../css/main.scss';

// custom imports
import $j from 'jquery';
import 'bootstrap';
import Blazy from 'blazy';
import { TweenMax, TimelineLite } from 'gsap/TweenMax';

import ScrollToPlugin from 'gsap/ScrollToPlugin';
const plugins = [ScrollToPlugin]; 

// import Js Plugins/Entities

//ES6 Module
import animations from './entities/animations';

//CommonJS
// var Bar2 = require('./entities/Bar2');


window.h5 = {
    isPc: function() {
        var userAgentInfo = navigator.userAgent;
        var Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod');
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
        }
        return flag;
    },
    rootResize: function() {
        //orientation portrait width=750px height=1334px / WeChat width=750px height=1206px 
        var wFsize;
        //screen.width screen.height  bug !!!
        // var wWidth = (screen.width > 0) ? (window.innerWidth >= screen.width || window.innerWidth == 0) ? screen.width :
        //     window.innerWidth : window.innerWidth;
        // var wHeight = (screen.height > 0) ? (window.innerHeight >= screen.height || window.innerHeight == 0) ?
        //     screen.height : window.innerHeight : window.innerHeight;
        var wWidth = window.innerWidth;
        var wHeight = window.innerHeight;
        if (wWidth > wHeight) {
            wFsize = wHeight / 750 * 100;
        } else {
            wFsize = wWidth / 750 * 100;
        }
        // document.getElementsByTagName('html')[0].style.fontSize = wFsize + 'px';
    },
    eventInit: function() {
        var that = this;
        document.addEventListener('touchstart', function(e) {}, false);
        // document.addEventListener('touchmove', function(e) {
        //     e.preventDefault();
        // }, false);
        return that;
    },
    cssInit: function() {
        var that = this;
        var noChangeCountToEnd = 100,
            noEndTimeout = 1000;
        that.rootResize();
        window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', function() {
            var interval,
                timeout,
                end,
                lastInnerWidth,
                lastInnerHeight,
                noChangeCount;
            end = function() {
                // "orientationchangeend"
                clearInterval(interval);
                clearTimeout(timeout);
                interval = null;
                timeout = null;
                that.rootResize();
            };
            interval = setInterval(function() {
                if (window.innerWidth === lastInnerWidth && window.innerHeight === lastInnerHeight) {
                    noChangeCount++;
                    if (noChangeCount === noChangeCountToEnd) {
                        // The interval resolved the issue first.
                        end();
                    }
                } else {
                    lastInnerWidth = window.innerWidth;
                    lastInnerHeight = window.innerHeight;
                    noChangeCount = 0;
                }
            });
            timeout = setTimeout(function() {
                // The timeout happened first.
                end();
            }, noEndTimeout);
        });

        return that;
    },
    init: function() {
        var that = this;
        that.cssInit().eventInit();
        BP.init();
    }
};
window.onload = function() {
    window.h5.init();
};

//Stats JavaScript Performance Monitor

// import Stats from 'stats.js';
// showStats();
// function showStats() {
//     var stats = new Stats();
//     stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
//     var fs = document.createElement('div');
//     fs.style.position = 'absolute';
//     fs.style.left = 0;
//     fs.style.top = 0;
//     fs.style.zIndex = 999;
//     fs.appendChild(stats.domElement);
//     document.body.appendChild(fs);

//     function animate() {
//         stats.begin();
//         // monitored code goes here
//         stats.end();
//         requestAnimationFrame(animate);
//     }
//     requestAnimationFrame(animate);
// }


window.BP = {
    
    init: function() {
        animations();
        this.menu.menuElement.on('click', function (e) { BP.menu.toggleMenu(e); }
        );
        this.menu.menuItems.on('click', function (e) { BP.menu.goToMenuLink(e, $j(this)) });
        this.testBlendModeSupport();
        this.addConsoleLogs();
        this.handleResizeEvent();
        this.updateYear();
        window.addEventListener('resize', function () {
            BP.handleResizeEvent();
        });
        var bLazy = new Blazy({
            selector: '.defer',
            successClass: 'defer-loaded',
            success: function (ele) {
                // Image has loaded
                // Do your business here
            }
            , error: function (ele, msg) {
                if (msg === 'missing') {
                    // Data-src is missing
                }
                else if (msg === 'invalid') {
                    // Data-src is invalid
                }
            },
            breakpoints: [{
                width: 768 // max-width
                , src: 'data-src-small'
            }]
        });
    },
    toggleActive: function (element) {
        if (element.hasClass('is-active')) {
            element.removeClass('is-active');
        }
        else {
            element.addClass('is-active');
        }
    },
    menu : {
        menuElement: $j('.hamburger'),
        menuItems: $j('.menu__item__link'),
        isMenuOpen: false,
        menuAnimation: new TimelineLite()
            .add([
                TweenMax.to($j('.hamburger-inner'), 0.5, {
                    backgroundColor:"#fff",
                    ease: Power2.easeInOut,
                    delay: -0.25}),
                TweenMax.to($j('.main-menu'), 0.5, {
                    autoAlpha: 1,
                    display: 'block',
                    ease: Power2.easeInOut,
                    delay: -0.25}),
                TweenMax.staggerFromTo($j('.menu__item'), 0.25, {
                    opacity:0,
                    y:-100,
                    scale: 1.2,
                    ease:
                    Power2.easeInOut
                }, {
                    opacity:1,
                    y:0,
                    scale: 1,
                    ease: Power2.easeInOut
                }, 0.1),
                TweenMax.staggerFromTo($j('.menu__link__icon'), 0.5, {
                    opacity:0,
                    scale: 1.2,
                    ease:
                    Power2.easeInOut
                }, {
                    delay: 0.5,
                    opacity:1,
                    scale: 1,
                    ease: Power2.easeInOut
                }, 0.1)
            ])
            // .onReversecomplete(window.BP.menu.menuElement.removeClass('is-active'))
            .pause(),
        toggleMenu: function() {
            if (this.isMenuOpen === false) {
                this.openMenu();
                this.menuElement.addClass('is-active');
                $j('body').addClass('modal-open');
                this.isMenuOpen = true;
            }
            else {
                this.closeMenu();
                this.menuElement.removeClass('is-active');
                $j('body').removeClass('modal-open');
                this.isMenuOpen = false;
            }
        },
        openMenu: function() {
            // this.menuAnimation.eventCallback('onComplete', BP.addConsoleLogs());
            // this.menuAnimation.eventCallback('onReverseComplete', this.menuElement.removeClass('is-active'));
            this.menuAnimation.play();
        },
        closeMenu: function() {
            this.menuAnimation.reverse();
        },
        goToMenuLink: function(event, element) {
            event.preventDefault();
            if (element.data('section') === 'who') {
                var scrollTarget = BP.target.sectionWho;
            }
            else {
                var scrollTarget = '.section__' + element.data('section');
            }
            this.menuAnimation.eventCallback('onReverseComplete',
                function(){
                    TweenMax.to(window, 1, {scrollTo:{y:scrollTarget, autoKill:false}, ease: Power1.easeInOut, onComplete:
                        function() {
                            //remove onreversecallback after its execution
                            BP.menu.menuAnimation.eventCallback('onReverseComplete', null);
                        }
                    });
                }
            );
            this.toggleMenu();
            
        },
        scrollToSection: function(scrollTarget) {
            TweenMax().to(window, 1, {scrollTo:scrollTarget});
            //this.menuAnimation.eventCallback("onReverseComplete", null);
        }
    },
    addConsoleLogs: function() {
        console.log('Hi there!');
        console.log('If you`re eager to find out how we do things, drop us a line at hello@baro-pfannenstein.de');
        console.log('By the way, we`re hiring!');
    },
    testBlendModeSupport: function() {
        if('CSS' in window && 'supports' in window.CSS) {
            var support = window.CSS.supports('mix-blend-mode','multiply');
            /* Add class like Modernizr */
            if (support) {
                document.querySelector('html').classList.add('mix-blend-mode');
            }
            else {
                document.querySelector('html').classList.add('no-mix-blend-mode');    
            }
        }
        else {
            document.querySelector('html').classList.add('no-mix-blend-mode');
        }
    },
    updateYear() {
        const date = new Date;
        $j('.year').html(date.getFullYear());
    },

    /**
     * Detect the current active responsive breakpoint in Bootstrap
     * @returns {string}
     * @author farside {@link https://stackoverflow.com/users/4354249/farside}
     */
    getResponsiveBreakpoint: function() {
        var envs = {xs:"d-none", sm:"d-sm-none", md:"d-md-none", lg:"d-lg-none", xl:"d-xl-none"};
        var env = "";

        var el = $j("<div>");
        el.appendTo($j("body"));

        for (var i = Object.keys(envs).length - 1; i >= 0; i--) {
            env = Object.keys(envs)[i];
            el.addClass(envs[env]);
            if (el.is(":hidden")) {
                break; // env detected
            }
        }
        el.remove();
        return env;
    },
    handleResizeEvent: function() {
        this.dimensions.width = window.innerWidth;
        this.dimensions.height = window.innerHeight;
    },
    target: {
        sectionIntro: $j('.section__intro'),
        sectionWho: $j('.section__who'),
        sectionWhat: $j('.section__what'),
        sectionClients: $j('.section__clients'),
        sectionContact: $j('.section__contact'),

        teaser: $j('.teaser'),
        logo: $j('.logo'),
        aaaWrapper: ('.aaa__wrapper'),
        aaaFirst: $j('.aaa__first'),
        aaaSecond: $j('.aaa__second'),
        aaaThird: $j('.aaa__third'),
        clientLogo: $j('.client__logo__wrapper')
    },
    dimensions: {
        width: window.innerWidth,
        height: window.innerHeight
    }

}