'use strict';



import { TweenMax, TimelineLite } from "gsap/TweenMax";
import 'animation.gsap';
import $j from 'jquery';
var ScrollMagic = require('ScrollMagic');
import 'debug.addIndicators';



export default function animations() {

    // Logo
    

    var logoController = new ScrollMagic.Controller({addIndicators: false});
    var logoDuration = BP.target.sectionWho.outerHeight() / 4;

    var logoTween = TweenMax.to(BP.target.logo, 1, {opacity:0, ease: Linear.easeNone});


    window.logoScene = new ScrollMagic.Scene({ duration: logoDuration, offset: 0})
        .setTween(logoTween)
        .addTo(logoController);


    // Clients
    

    var clientController = new ScrollMagic.Controller({addIndicators: false});
    
    var clientTween = new TimelineLite ()
        .add([
            TweenMax.staggerTo($j('.client__logo__wrapper'), 1, {opacity: 1, ease: Linear.easeNone}, 0.4),
        ]);

        let offset;
        if (window.innerWidth < 500) {
            offset = -100;
        }
        else {
            offset = -200;
        }
    window.clientScene = new ScrollMagic.Scene({
        triggerElement: '.section__clients',
        offset: offset,
        duration: $j('.section__clients').height()/1.5,
    })
        .setTween(clientTween)
        .addTo(clientController);




    window.addEventListener('resize', function(){
        // reset progress to start so changes do not occur from current position but from start
        // logoTween.progress(0)
        // set set new tween parameters
        var logoParams = {css : {y : BP.target.sectionIntro.outerHeight() - BP.target.logo.outerHeight() }};

        // logoTween.updateTo(logoParams, true);
        // re-add tween to reset position and to update
        // logoScene.duration(BP.target.sectionIntro.outerHeight() / 1.5);
        // logoScene.setTween(logoTween);


        // // reset progress to start so changes do not occur from current position but from start
        // teaserTween.progress(0)
        // // set set new tween parameters
        // var teaserParams = {css : {y : BP.dimensions.height/1.5 }};

        // teaserTween.updateTo(teaserParams, true);
        // // re-add tween to reset position and to update
        // teaserScene.duration(BP.target.sectionIntro.outerHeight());
        // teaserScene.setTween(teaserTween);
    });

}



